<template>
    <div id="home">
        <!-- <Header></Header>
        <Nav></Nav> -->
        <div class="container">
            <div class="left_wrapper">
                <Total :NationalMarket="nationalMarket" :Trade="trade"></Total>
                <News></News>
            </div>
            <div class="right_wrapper">
                <Table ref="table_ref"></Table>
            </div>
        </div>
        <!-- <Footer></Footer> -->
    </div>
</template>

<script>
// @ is an alias to /src
// import Header from '@/components/Header';
// import Nav from './components/Nav.vue';
// import Footer from '@/components/Footer';
import Total from "./components/Total.vue";
import News from "./components/News.vue";
import Table from "./components/table.vue";
export default {
    name: "Home",
    components: {
        // Header,
        // Nav,
        // Footer,
        Total,
        News,
        Table,
        // activated() {
        //   this.getNationalMarket();
        //   this.getTrade();
        // },
    },
    data() {
        return {
            nationalMarket: {},
            trade: {},
        };
    },
    created() {
        // this.getNationalMarket();
        // this.getTrade();
    },
    activated () {
        console.log('activated, home');
        
    },
    async mounted() {
        this.nationalMarket = await this.$refs.table_ref.getTableData('only');
        this.trade = await this.$refs.table_ref.getTableDataExpand('only');
        console.log('mounted, Home', this.nationalMarket, this.trade);
    },
    methods: {
        // getNationalMarket() {
        //     //   http://192.168.23.15:8000/article/carbonpricelist
        //     this.api
        //         .postFormAPISM(
        //             {
        //                 page: 1,
        //                 limit: 1,
        //             },
        //             "/article/carbonpricelist"
        //         )
        //         .then((res) => {
        //             this.nationalMarket = res.data.data;
        //             console.log("getNationalMarket111", this.nationalMarket);
        //         })
        //         .catch((error) => {
        //             console.log("error", error);
        //         });
        // },
        // getTrade() {
        //     // http://192.168.23.15:8000/http://192.168.23.15:8000/article/carbonsalelist
        //     this.api
        //         .postFormAPISM(
        //             {
        //                 page: 1,
        //                 limit: 1,
        //             },
        //             "/article/carbonsalelist"
        //         )
        //         .then((res) => {
        //             this.trade = res.data.data;
        //             // console.log("getTrade111", this.trade);
        //         })
        //         .catch((error) => {
        //             console.log("error", error);
        //         });
        // },
    },
};
</script>
<style lang="less">
#home {
    width: 1200px;
    // min-height: calc(100% - 180px - 120px);
    flex-grow: 1;
    overflow: auto;
    padding: 37px 0 0;
    margin: 0 auto;

    &>div {
        &.container {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            overflow: hidden;

            &>div {
                flex-grow: 0;
                flex-shrink: 0;
                padding: 0 10px;

                &.left_wrapper {
                    width: 760px;
                    padding: 0 30px 0 10px;
                }

                &.right_wrapper {
                    // width: 500px;
                }
            }
        }
    }
}
</style>
