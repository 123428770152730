var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"total_wrapper"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"name"},[_vm._v("碳行情(全国市场)")]),_c('div',{staticClass:"value",style:({
            color:
                _vm.nationalMarket.percent !== '' &&
                    '' &&
                    _vm.nationalMarket.percent.startsWith('-')
                    ? '#07C160'
                    : /^0.0+%$/.test(_vm.nationalMarket.percent)
                        ? ''
                        : '#ff2832',
        })},[_c('span',[_vm._v(_vm._s(_vm.nationalMarket.start_price || " "))]),_c('div',{on:{"click":_vm.toCarbonMarket}},[_c('e-charts',{staticClass:"chart",attrs:{"option":_vm.leftOption}})],1)]),_c('div',{staticClass:"percent",style:({
            color:
                _vm.nationalMarket.percent && _vm.nationalMarket.percent.startsWith('-')
                    ? '#07C160'
                    : /^0.0+%$/.test(_vm.nationalMarket.percent)
                        ? ''
                        : '#ff2832',
        })},[_vm._v(" "+_vm._s(_vm.nationalMarket.percent || " ")+" "),(_vm.nationalMarket.amplitude)?[_vm._v(" ( "+_vm._s(_vm.nationalMarket.amplitude)+" ) ")]:_vm._e()],2)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"name"},[_vm._v("全国碳市场（大宗交易）")]),_c('div',{staticClass:"value",style:({
            color:
                _vm.trade.discount_premium && _vm.trade.discount_premium.startsWith('-')
                    ? '#07C160'
                    : /^0.0+%$/.test(_vm.trade.discount_premium)
                        ? ''
                        : '#ff2832',
        })},[_c('span',[_vm._v(_vm._s(_vm.trade.total || " "))]),_c('div',{on:{"click":_vm.toCarbonBazaar}},[_c('e-charts',{staticClass:"chart",staticStyle:{"margin-left":"20px","padding-bottom":"38px"},attrs:{"option":_vm.rightOption}})],1)]),_c('div',{staticClass:"percent",style:({
            color:
                _vm.trade.discount_premium && _vm.trade.discount_premium.startsWith('-')
                    ? '#07C160'
                    : /^0.0+%$/.test(_vm.trade.discount_premium)
                        ? ''
                        : '#ff2832',
        })},[_vm._v(" "+_vm._s(_vm.trade.average_price || " ")+" "),(_vm.trade.discount_premium)?[_vm._v(" ( "+_vm._s(_vm.trade.discount_premium)+" ) ")]:_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }