<template>
  <div class="table_wrapper">
    <div class="title">碳行情（全国市场）</div>
    <el-table ref="multipleTable" :data="tableData">
      <!--  fontFamily: 'Arial Normal, Arial, sans-serif', -->
      <!-- 日期 -->
      <el-table-column
        prop="current_date"
        class="date"
        label="日期"
        align="left"
        width="80px"
        class-name="current_date"
        header-align="left"
      >
      </el-table-column>
      <el-table-column
        prop="end_price"
        label="最新价"
        width="50px"
        align="right"
        class-name="end_price"
        header-align="right"
      >
      </el-table-column>
      <!-- 涨跌 -->
      <el-table-column
        prop="percent"
        label="涨跌"
        width="60px"
        class-name="percent"
        header-align="right"
        align="right"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.percent.startsWith('-')" style="color: #07C160">
            {{ scope.row.percent }}
          </span>
          <span v-else-if="/^0.0+%$/.test(scope.row.percent)">
            {{ scope.row.percent }}
          </span>
          <span
            v-else="scope.row.percent.startsWith('+')"
            style="color: #ff2832"
          >
            {{ scope.row.percent }}
          </span>
        </template>
        <span> {{}} </span>
      </el-table-column>
      <!-- 成交量 -->
      <el-table-column
        prop="amount"
        label=" 成交量"
        width="65"
        class-name="amount"
        header-align="right"
        align="right"
      >
      </el-table-column>
      <!-- 成交额 -->
      <el-table-column
        prop="total"
        class-name="total"
        label=" 成交额"
        width="95px"
        align="right"
        header-align="right"
      >
      </el-table-column>
      <!-- 振幅 -->
      <el-table-column
        prop="amplitude"
        class-name="amplitude"
        label=" 振幅"
        width="60"
        header-align="right"
        align="right"
      >
      </el-table-column>
    </el-table>
    <div class="title expand">全国碳市场(大宗交易)</div>
    <el-table ref="multipleTable" :data="tableData_Expand">
      <el-table-column
        prop="current_date"
        label="日期"
        align="left"
        width="80px"
        class-name="current_date"
        header-align="left"
      >
      </el-table-column>
      <el-table-column
        prop="amount"
        label="成交量"
        width="75"
        align="right"
        header-align="right"
      >
      </el-table-column>
      <el-table-column
        prop="total"
        label="成交额"
        width="110"
        align="right"
        header-align="right"
      >
      </el-table-column>
      <el-table-column
        prop="average_price"
        label="均价"
        width="55"
        align="right"
        header-align="right"
      >
      </el-table-column>
      <el-table-column
        prop="discount_premium"
        label="折溢价"
        width="75"
        align="right"
        header-align="right"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.discount_premium.startsWith('-')"
            style="color: #07C160"
          >
            {{ scope.row.discount_premium }}
          </span>
          <span v-else-if="/^0.0+%$/.test(scope.row.discount_premium)">
            {{ scope.row.discount_premium }}
          </span>
          <span
            v-else="scope.row.discount_premium.startsWith('+')"
            style="color: #ff2832"
          >
            {{ scope.row.discount_premium }}
          </span>
        </template>
        <!-- <span> {{}} </span> -->
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "TableWrapper",
  data() {
    return {
      pagination: {
        page: 1,
        limit: 20,
        page_count: 0,
      },
      tableData: [],
      tableData_Expand: [],
    };
  },
  activated() {
    console.log("activated, Table");
  //  this.getTableData();
    // this.getTableDataExpand();
  },
  // created() {
  //   this.getTableData();
  //   this.getTableDataExpand();
  // },
  methods: {
    async getTableData(type) {
      return new Promise(resolve => {
        // http://192.168.23.15:8000/article/carbonpricelist
        this.api
          .postFormAPISM(
            {
              ...this.pagination,
            },
            "/article/carbonpricelist"
          )
          .then((res) => {
            console.log("getTableData", res.data);
            this.tableData = res.data.data.daily_price_list;
            this.pagination.page_count = res.data.data.pagination.page_count;
            resolve(this.tableData[0])
            // console.log(res.data.data.pagination.page_count,'res.data.data.pagination.page_count');
            // console.log(
            //   res.data.data.daily_price_list,
            //   "res.data.data.daily_price_list"
            // );
          })
          .catch((error) => {
            console.log("error", error);
          });
        // console.log(this.$store.state.oneNationalMarket,'kankanwo1');
        // this.tableData = this.$store.state.oneNationalMarket.daily_price_list;
        // this.pagination.page_count = this.$store.state.oneNationalMarket.pagination.page_count;
    })

    },
    async getTableDataExpand() {
      return new Promise(resolve =>{      
        // http://192.168.23.15:8000/article/carbonsalelist
        this.api
          .postFormAPISM(
            {
              ...this.pagination,
            },
            "/article/carbonsalelist"
          )
          .then((res) => {
            console.log("111getTableData", res.data);
            this.tableData_Expand = res.data.data.daily_sale_list;
            // this.pagination.page_count = res.data.data.pagination.page_count;
            resolve(this.tableData_Expand[0]);
          })
          .catch((error) => {
            console.log("error", error);
          });
        // this.tableData_Expand = this.$store.state.oneTrade.daily_sale_list;
      })
    },
  },
};
</script>
<style lang="less">
.table_wrapper {
  // padding: 28px 0 0 0;
  & > .title {
    height: 26px;
    margin: 0 0 10px;
    display: flex;
    font-family: "微软雅黑", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #33353c;
    &.expand {
      margin: 30px 0 10px;
    }
  }
  & > .el-table {
    &::before {
      display: none;
    }
    & .el-table__body tr:hover > td.el-table__cell {
      background-color: transparent;
    }
    & th.el-table__cell {
      border-top: 1px solid #c5c5c5;
      border-bottom: 1px solid #c5c5c5;
      color: rgb(51, 51, 51);
      font-size: 12px;
      font-weight: 400;
      padding: 5px 0px;
      & > .cell {
        padding: 0 6px;
      }
      &.percent {
        & > .cell {
          padding: 0 0 0 12px;
        }
      }
      &.amplitude {
        & > .cell {
          padding: 0 6px 0 12px;
        }
      }
    }
    & td.el-table__cell {
      border-bottom: 1px solid #d7d7d7;
      color: rgb(51, 51, 51);
      font-size: 12px;
      font-weight: 400;
      padding: 5px 0px;
      & > .cell {
        // padding: 0;
        padding: 0 6px;
      }
      &.percent {
        & > .cell {
          padding: 0 0 0 12px;
        }
      }
      &.amplitude {
        & > .cell {
          padding: 0 6px 0 12px;
        }
      }
    }
  }
}
</style>
