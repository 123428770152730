<template>
    <div class="news_wrapper">
        <div class="title">

            <span :class="active === index ? 'active' : ''" v-for="(item, index) in titles" :key="index"
                @click="activeChange(index)">{{ item.name }}</span>
        </div>
        <div class="news" v-show="active === 0">
            <div class="item" v-for="(item, index) in news" :key="item.id" @click="handleView(item)">
                <div class="title" v-html="item.title">
                </div>
                <a class="content" v-html="item.content">
                </a>
                <div class="source">
                    <span class="time">{{ item.send_time }}</span>
                    <span class="source"> 来源：{{ item.source }} </span>
                </div>
            </div>
            <div v-if="empty" class="empty">暂无结果</div>
        </div>
        <div class="news" v-show="active === 1">
            <div class="item" v-for="(item, index) in rencentNews" :key="item.id" @click="handleView(item)">
                <div class="title" v-html="item.title">
                </div>
                <a class="content" v-html="item.content">
                </a>
                <div class="source">
                    <span class="time">{{ item.send_time }}</span>
                    <span class="source"> 来源：{{ item.source }} </span>
                </div>
            </div>
            <div v-if="empty" class="empty">暂无结果</div>
        </div>
       
        <div :class="['more', { 'loading': loading }]" v-loading="loading">
            <span v-show="scrollCount > 2 && more" @click="handleMore">加载更多</span>
        </div>
    </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ImageBasicUrl } from "../../../api/api";
// eslint-disable-next-line no-unused-vars
import { imageTemplate } from "../../../utils/util";
export default {
    name: "NewsWrapper",
    data() {
        return {
            pagination: {
                page: 1,
                limit: 20,
                page_count: 0,
            },
            news: [],
            rencentNews: [],
            // last_create_time:'',
            more: true,
            // month: '',
            // day: '',
            scrollCount: 0,
            loading: false,
            empty: false,
            titles: [
                {
                    name: '碳问推荐'
                },
                {
                    name: '7*24'
                }
            ],
            active: 0,
        };
    },
    created() {
        console.log('created, new component');
        this.$EventBus.$on("search", (keywords) => {
            //需要执行的代码
            console.log("NewsWrapper, EventBus, search", keywords);
            this.pagination = {
                ...this.$options.data().pagination,
            };
            this.scrollCount = 0;
            this.more = true;
            (document.documentElement || document.body).scrollTop = 0;
            this.getNews(keywords);
        });
    },
    activated() {
        console.log('activated, News-component');
        this.loading = false;
        this.more = true;
        this.scrollCount = 0;
        this.getNews();
        window.addEventListener("scroll", this.handleScroll);
    },
    deactivated() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        getNews() {
            console.log('getNews');
            let keyword = this.$route.query.keyword;
            let tags = this.$store.state.userInfo.tag_list
            // http://192.168.23.15:8000/article/home
            this.empty = false;
            this.api.postFormAPISM(
                {
                    ...this.pagination,
                    keywords: keyword || "",
                    start_time: '',
                    tags: tags,
                    tag_scored:0,
                },
                "/article/home"
            ).then((res) => {
                console.log('getNews, then');
                let temp = res.data.data.article_info_list;
                this.more = temp.length === this.pagination.limit;
                // console.log('for-before');
                if (!temp.length) {
                    this.empty = true;
                }

                for (let i = 0; i < temp.length; i++) {
                    // console.log('for');
                    temp[i].title = temp[i].title.replace(keyword, `<span>${keyword}</span>`);
                    temp[i].content = this.replaceImage(temp[i].content, temp[i].id, i, temp[i].title);
                }
                this.news = temp;

            }).catch((error) => {
                console.log("error", error);
            });
        },
        getRencentNews() {
            console.log('getRencentNews');
            let keyword = this.$route.query.keyword;
            // http://192.168.23.15:8000/article/home
            this.empty = false;
            this.api.postFormAPISM(
                {
                    ...this.pagination,
                    keywords: keyword || "",
                    start_time: '',
                },
                "/article/home"
            ).then((res) => {
                console.log('getNews, then');
                let temp = res.data.data.article_info_list;
                this.more = temp.length === this.pagination.limit;
                // console.log('for-before');
                if (!temp.length) {
                    this.empty = true;
                }
                for (let i = 0; i < temp.length; i++) {
                    temp[i].title = temp[i].title.replace(keyword, `<span>${keyword}</span>`);
                    temp[i].content = this.replaceImage(temp[i].content, temp[i].id, i, temp[i].title);
                }
                this.rencentNews = temp;    
            }).catch((error) => {
                console.log("error", error);
            });
        },
        replaceImage(content, id, index, title) {
            let reg = /({{[^}}]+.(bmp|jpg|png|tif|gif|pcx|tga|exif|fpx|svg|psd|cdr|pcd|dxf|ufo|eps|ai|raw|WMF|webp|avif|apng)}})/g;
            let reg2 = /^{{([^}}]+)}}$/;
            let length = 300;
            // console.log('replaceImage');
            let temp = content.replace(/<br>|\s/g, "");
            let keyword = this.$route.query.keyword;
            if (keyword) {
                //  \u4e00-\u9fa5
                // new RegExp(`[,.，。!]*([^,.，。]{5,200}${ keyword }.{150,200})`);
                let reg = new RegExp(`([^,.，。]{0,50}${keyword}.{0,250})`);
                // if (!index){
                //     console.log('reg', reg);
                //     console.log(reg.exec(temp));
                // }
                let outcome = reg.exec(temp);
                if (outcome instanceof Array && outcome[1]) {
                    temp = outcome[1].replaceAll(keyword, `<span>${keyword}</span>`);
                }
                // if (title.includes('江苏省生态环境厅')){
                //     console.log('reg', reg);
                //     console.log('outcome', outcome);
                // }
                // let firstIndex = temp.indexOf(keyword);
                // console.log('firstIndex', firstIndex);
                // let target = '';
                // let sliceString = (temp, startIndex) => {
                //     // let string = temp;

                //     if (startIndex - 1 === 0){
                //         target += temp[0]
                //     }
                // }
            }
            // console.log('replaceImage', content.match(/<br>/g));
            let tempImage = temp.match(reg) || [];
            tempImage.length && console.log("tempImage", id);
            for (let i = 0; i < tempImage.length; i++) {
                let url = reg2.exec(tempImage[i]);
                if (url instanceof Array) {
                    url = url[1];
                    temp = temp.replace(tempImage[i], "");
                    // temp = temp.replace(tempImage[i], imageTemplate(ImageBasicUrl + url));
                }
            }
            // function getSliceStr(str){
            //     if ( str.length < length || (/[\u4e00-\u9fa5]/.test(str[length]) && /[\u4e00-\u9fa5]/.test(str[length-1]))){
            //         return str.slice(0, length) + (!(str.length < length) ? '....' : '');
            //     } else {
            //         length++;
            //         return getSliceStr(str);
            //     }
            // }
            // temp = getSliceStr(temp);
            // console.log('replaceImage, length', length, temp.length);
            return temp;
        },
        handleMore(text) {
            console.log("handleMore", text, this.scrollCount);
            this.pagination.page++;
            let keyword = this.$route.query.keyword;
            let tags = this.$store.state.userInfo.tag_list
            // 碳问推荐
            if(this.active === 0){
            // this.pagination.limit += 20;
            this.api
                .postFormAPISM(
                    {
                        ...this.pagination,
                        keywords: keyword || "",
                        start_time: this.news[this.news.length - 1].send_time,
                        tag_scored: this.news[this.news.length - 1].tag_scored,
                        tags: tags
                    },
                    "/article/home"
                )
                .then((res) => {
                    this.news = [
                        ...this.news,
                        ...res.data.data.article_info_list.map((item) => {
                            return {
                                ...item,
                                title: item.title.replace(keyword, `<span>${keyword}</span>`),
                                content: this.replaceImage(item.content, item.id, item.title),
                            };
                        }),
                    ];
                    this.more = res.data.data.article_info_list.length === this.pagination.limit;
                    if (text) {
                        this.loading = false;
                        this.scrollCount++;
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                });
            }
            // 7*24
            else{
                this.api
                .postFormAPISM(
                    {
                        ...this.pagination,
                        keywords: keyword || "",
                        start_time: this.rencentNews[this.rencentNews.length - 1].send_time,
                    },
                    "/article/home"
                )
                .then((res) => {
                    console.log('recentTime',this.rencentNews[this.rencentNews.length - 1].send_time);
                    console.log('recentNews',res.data.data.article_info_list);
                
                //      // 纪录每页新闻列表的最后一个帖子的创造时间
                //   this.last_create_time = res.data.data.article_info_list[res.data.data.article_info_list.length-1].send_time;
                //    // 格式化日期时间
                //    if(res.data.data.article_info_list.length !==0)
                //    {
                //     res.data.data.article_info_list.forEach(item => {
                //     item.send_time = item.send_time.split(' ')[1];
                // });
                //    }
                    this.rencentNews = [
                        ...this.rencentNews,
                        ...res.data.data.article_info_list.map((item) => {
                            return {
                                ...item,
                                title: item.title.replace(keyword, `<span>${keyword}</span>`),
                                content: this.replaceImage(item.content, item.id, item.title),
                            };
                        }),
                    ];
                    
                    this.more = res.data.data.article_info_list.length === this.pagination.limit;
                    if (text) {
                        this.loading = false;
                        this.scrollCount++;
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                });
            }
        },
        handleView({ title, id }) {
            // this.$router.push(
            //     {
            //         path: '/news',
            //         query: {
            //             page: page + 1,
            //         }
            //     }
            // );
            let text = this.$router.resolve({
                path: "/news",
                query: {
                    id,
                },
            });
            console.log("text", text.href);
            // 打开一个新的页面
            window.open(text.href, "_blank");
        },
        handleScroll() {
            if(this.active === 0)
            {
            if (!this.news.length  || this.loading || this.scrollCount > 2) return;
            }
            else{
                if (!this.rencentNews.length  || this.loading || this.scrollCount > 2) return;
            }
            // 距顶部
            var scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            // 可视区高度
            var clientHeight =
                document.documentElement.clientHeight || document.body.clientHeight;
            // 滚动条总高度
            var scrollHeight =
                document.documentElement.scrollHeight || document.body.scrollHeight;
            let targetHeight = scrollHeight - 257 - 140;
            // console.log(scrollTop, clientHeight, scrollHeight, targetHeight,  targetHeight - scrollTop - clientHeight);
            if (targetHeight - scrollTop - clientHeight < 10) {
                this.loading = true;
                // return
                setTimeout(() => {
                    this.handleMore("scroll");
                }, 500);
            }
        },
        // formatMonth(send_time) {
        //     // 获取月份
        //     let month = send_time.split('-')[1];
        //     let months = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二']
        //     // 月份小于10，则去掉前面的0
        //     if (month > 0 && month < 10) {
        //         month = send_time.split('-')[1].split('')[1];
        //     }
        //     switch (month) {
        //         case '1': month = months[0];
        //             break;
        //         case '2': month = months[1];
        //             break;
        //         case '3': month = months[2];
        //             break;
        //         case '4': month = months[3];
        //             break;
        //         case '5': month = months[4];
        //             break;
        //         case '6': month = months[5];
        //             break;
        //         case '7': month = months[6];
        //             break;
        //         case '8': month = months[7];
        //             break;
        //         case '9': month = months[8];
        //             break;
        //         case '10': month = months[9];
        //             break;
        //         case '11': month = months[10];
        //             break;
        //         default: month = months[11];

        //     }
        //     return month;
        // },
        activeChange(index) {
            this.active = index;
            this.loading = false;
           this.more = true;
           this.scrollCount = 0;
           this.empty=false;
           this.pagination={
                page: 1,
                limit: 20,
                page_count: 0,
            };
            if (this.active === 0) {
                
                this.getNews();
            }
            else {
                this.getRencentNews();
            }
        }
    }
};
</script>
<style lang="less">
.news_wrapper {

    //   padding: 0 25px 0 0;
    &>.title {
        width: 100%;
        height: 35px;
        border-bottom: 1px solid rgba(215, 215, 215, 1);

        &>span {
            font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
            font-weight: 700;
            display: inline-block;
            //   padding: 0 0 0 4px;
            line-height: 32px;
            margin-right: 16px;
            cursor: pointer;

            &.active {
                color: #058e44;
                border-bottom: 3px solid #058e44;
            }
        }
    }

    &>.news {
        &>.item {
            padding: 16px 0 25px;
            border-bottom: 1px solid rgba(215, 215, 215, 1);
            cursor: pointer;

            &:hover {
                background-color: #f9f9f9;
            }

            &>.title {
                font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
                font-weight: 700;
                font-style: normal;
                font-size: 18px;
                color: #00000f;
                cursor: pointer;

                &:hover {
                    color: #ff2832;
                }

                &>span {
                    color: #ff2832;
                }
            }

            &>.content {
                // display: block;
                // color: #666c72;
                font-size: 16px;
                margin: 20px 0;
                font-family: "微软雅黑", sans-serif;
                font-weight: 400;
                font-style: normal;
                color: #33353c;
                line-height: 24px;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }

                &>span {
                    color: #ff2832;
                }

                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            &>.source {
                &>span {
                    font-size: 12px;
                    padding: 0 0 0 6px;

                    &.time {
                        color: #a7a6a6;
                    }

                    &.source {
                        padding: 0 0 0 30px;
                    }
                }
            }

            &:not(:first-child) {
                padding-top: 20px;
            }

        }

        &>.empty {
            padding: 20px 0 0;
            color: rgb(150, 150, 150);
        }
    }

    // &>.news-right {
    //     &>.new-right-item {
    //         &>.main-time {
    //             padding-bottom: 10px;
    //             border-bottom: 1px solid #edf0f5;
    //             margin: 20px 0 30px;

    //             &>.main-time-month {
    //                 font-size: 30px;
    //                 color: #058e44;
    //                 margin-right: 3px;
    //                 vertical-align: -3px;
    //             }
    //         }

    //         &>.time-line-item {
    //             font-size: 15px;
    //             line-height: 1.6;

    //             & tr {
    //                 &>td:first-of-type {
    //                     color: #33353c;
    //                 }

    //                 &>td:nth-of-type(2) {
    //                     position: relative;

    //                     &>.time-line-point {
    //                         display: block;
    //                         position: relative;
    //                         z-index: 1;
    //                         padding: 0 10px;
    //                         background: #fff;

    //                         &::before {
    //                             content: '';
    //                             display: inline-block;
    //                             width: 10px;
    //                             height: 10px;
    //                             border-radius: 50%;
    //                             background-color: #edf0f5;
    //                         }
    //                     }

    //                     &>.time-line-lines {
    //                         position: absolute;
    //                         top: 0;
    //                         left: 50%;
    //                         width: 4px;
    //                         height: 100%;
    //                         margin-left: -2px;
    //                         background-color: #edf0f5;
    //                     }
    //                 }

    //                 &>td {
    //                     padding: 0 0 40px;
    //                     vertical-align: top;

    //                     &>.content {
    //                         overflow: hidden;
    //                         text-overflow: ellipsis;
    //                         display: -webkit-box;
    //                         -webkit-line-clamp: 6;
    //                         -webkit-box-orient: vertical;
    //                         cursor: pointer;

    //                         &:hover {
    //                             background-color: #f9f9f9;

    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     &>.empty {
    //         padding: 20px 0 0;
    //         color: rgb(150, 150, 150);
    //     }
    // }

    &>.more {
        padding: 10px 0 0;

        &.loading {
            padding: 35px 0 0;
        }

        &>span {
            display: block;
            padding: 2px 0;
            cursor: pointer;
            width: 296px;
            margin: 0 auto;
            text-align: center;
            font-family: "Arial Normal", "Arial", sans-serif;
            font-weight: 400;
            font-style: normal;
            // font-size: 13px;
            // color: #15a3f0;
            font-size: 14px;
            color: #058E44;
            background-color: rgb(230, 241, 251);
        }

        & .el-loading-spinner {
            margin: 0;
            top: 10px;

            & .circular {
                height: 26px;
                width: 26px;
            }
        }
    }
}</style>