<template>
    <div class="total_wrapper">
        <div class="item">
            <div class="name">碳行情(全国市场)</div>
            <div class="value" :style="{
                color:
                    nationalMarket.percent !== '' &&
                        '' &&
                        nationalMarket.percent.startsWith('-')
                        ? '#07C160'
                        : /^0.0+%$/.test(nationalMarket.percent)
                            ? ''
                            : '#ff2832',
            }">
                <span>{{ nationalMarket.start_price || "&nbsp;" }}</span>
                <div @click="toCarbonMarket">
                    <e-charts class="chart" :option="leftOption" />
                </div>
            </div>
            <div class="percent" :style="{
                color:
                    nationalMarket.percent && nationalMarket.percent.startsWith('-')
                        ? '#07C160'
                        : /^0.0+%$/.test(nationalMarket.percent)
                            ? ''
                            : '#ff2832',
            }">
                {{ nationalMarket.percent || "&nbsp;" }}
                <template v-if="nationalMarket.amplitude">
                    (
                    {{ nationalMarket.amplitude }}
                    )
                </template>
            </div>
        </div>
        <div class="item">
            <div class="name">全国碳市场（大宗交易）</div>
            <div class="value" :style="{
                color:
                    trade.discount_premium && trade.discount_premium.startsWith('-')
                        ? '#07C160'
                        : /^0.0+%$/.test(trade.discount_premium)
                            ? ''
                            : '#ff2832',
            }">
                <span>{{ trade.total || "&nbsp;" }}</span>
                <div @click="toCarbonBazaar">
                    <e-charts class="chart" :option="rightOption" style="margin-left: 20px; padding-bottom: 38px" />
                </div>
                <!-- <img
          v-if="trade.total"
          width="62px"
          height="48px"
          src="../../../assets/images/u50.png"
          alt=""
          srcset=""
          @click="toCarbonBazaar"
        /> -->
            </div>
            <div class="percent" :style="{
                color:
                    trade.discount_premium && trade.discount_premium.startsWith('-')
                        ? '#07C160'
                        : /^0.0+%$/.test(trade.discount_premium)
                            ? ''
                            : '#ff2832',
            }">
                {{ trade.average_price || "&nbsp;" }}
                <template v-if="trade.discount_premium">
                    (
                    {{ trade.discount_premium }}
                    )
                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "TotalWrapper",
    data() {
        return {
            leftECharts: [],
            newRightECharts: [],
            rightECharts: [],
            leftEChartsColor: "",
            rightEChartsColor: "",
        };
    },
    props: {
        NationalMarket: {
            type: Object,
            default: () => {
                return {
                    start_price: "",
                    percent: "",
                    amplitude: "",
                };
            },
        },
        Trade: {
            type: Object,
            default: () => {
                return {
                    total: "",
                    average_price: "",
                    discount_premium: "",
                };
            },
        },
    },
    // activated() {
    //   this.getNationalMarket();
    //   this.getTrade();
    // },
    created() {
        // this.getNationalMarket();
        // this.getTrade();
        this.getTrendEchartsData();
        this.getTableDataExpand();

        console.log("created, total", this.nationalMarket, this.trade);
    },
    mounted() {
        console.log("mounted, total", this.nationalMarket, this.trade);
    },
    computed: {
        nationalMarket: function () {
            return this.NationalMarket;
        },
        trade: function () {
            return this.Trade;
        },
        // 碳行情echarts图表渲染
        leftOption() {
            return {
                xAxis: {
                    splitLine: { show: false },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: { show: false },
                    type: "category",
                },
                yAxis: {
                    type: "value",
                    // boundaryGap: [0, 0.01],
                    splitLine: { show: false },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: { show: false },
                },
                grid: {
                    height: "50px",
                    width: "60px",
                },
                series: [
                    {
                        data: this.leftECharts,
                        type: "line",
                        smooth: 0.6,
                        symbol: "none",
                        itemStyle: {
                            color: this.leftEChartsColor,
                        },
                    },
                ],
            };
        },
        // 全国碳市场echarts图表渲染
        rightOption() {
            return {
                xAxis: {
                    splitLine: { show: false },
                    axisLine: { show: false, lineStyle: { color: "red" } },
                    axisTick: { show: false },
                    axisLabel: { show: false },
                    type: "category",
                },
                yAxis: {
                    type: "value",
                    // boundaryGap: [0, 0.01],
                    splitLine: { show: false },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: { show: false },
                },
                grid: {
                    height: "50px",
                    width: "60px",
                },
                series: [
                    {
                        data: this.rightECharts,
                        type: "line",
                        smooth: 0.6,
                        symbol: "none",
                        itemStyle: {
                            color: this.rightEChartsColor,
                        },
                    },
                ],
            };
        },
    },
    methods: {
        // 全国碳市场echarts图表数据
        getTableDataExpand() {
            return new Promise(resolve => {
                // http://192.168.23.15:8000/article/carbonsalelist
                this.api.postFormAPISM(
                        {
                            ...this.pagination,
                        },
                        "/article/carbonsalelist"
                ).then((res) => {
                    console.log("dddd",res);
                    for (let i = 0; i < res.data.data.daily_sale_list.length; i++) {
                        this.rightECharts.push(
                            res.data.data.daily_sale_list[i].average_price
                        );
                    }
                    this.rightECharts.reverse();
                    console.log('this.rightECharts',this.rightECharts);
                    if (this.rightECharts[0] < this.rightECharts[1]) {
                        this.rightEChartsColor = "rgb(255, 40, 50)";
                        
                    }
                    if (this.rightECharts[0] > this.rightECharts[1]) {
                        // this.rightEChartsColor = "rgba(255,40,50,1)";
                        this.rightEChartsColor = "rgb(82, 167, 123)";
                    }
                    if (this.rightECharts[0] == this.rightECharts[1]) {
                        // this.rightEChartsColor = "rgba(87,94,120,1)";
                        this.rightEChartsColor = "rgba(87,94,120,1)";
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                });
                // this.tableData_Expand = this.$store.state.oneTrade.daily_sale_list;
            })
        },
        //  碳行情echarts图表数据
        getTrendEchartsData() {
            this.api
                .postFormAPISM(
                    {
                        limit: 999999,
                        send_time: "",
                    },
                    "/prices/getcarbonpricetrend"
                )
                .then((res) => {
                    // console.log(res,'看看我');
                    // splice()
                    this.leftECharts = res.data.data.china_prices
                        .splice(0, 377)
                        .reverse();
                    // console.log(this.leftECharts,'看看我');
                    // console.log(this.leftECharts[this.leftECharts.length - 1], "0");
                    // console.log(this.leftECharts[this.leftECharts.length - 2], "1");
                    if (
                        this.leftECharts[this.leftECharts.length - 1] <
                        this.leftECharts[this.leftECharts.length - 2]
                    ) {
                        // this.leftEChartsColor = "rgba(7, 193, 96,1)";
                        this.leftEChartsColor = "rgb(255, 40, 50)";
                        // console.log("第1次");
                    }
                    if (
                        this.leftECharts[this.leftECharts.length - 1] >
                        this.leftECharts[this.leftECharts.length - 2]
                    ) {
                        // this.leftEChartsColor = "rgba(255,40,50,1)";
                        this.leftEChartsColor = "rgb(82, 167, 123)";
                        // console.log("第2次");
                    }
                    if (
                        this.leftECharts[this.leftECharts.length - 1] ==
                        this.leftECharts[this.leftECharts.length - 2]
                    ) {
                        // this.leftEChartsColor = "rgba(87,94,120,1)";
                        this.leftEChartsColor = "rgba(87,94,120,1)";
                        // console.log("第3次");
                    }
                })
                .catch((error) => {
                    console.log("getTrendEchartsData, error", error);
                });
        },
        // 去碳行情
        toCarbonMarket() {
            this.$router.push("/carbonMarket");
        },
        // 去全国碳市场
        toCarbonBazaar() {
            this.$router.push("/carbonBazaar");
        },
        getNationalMarket() {
            //   http://192.168.23.15:8000/article/carbonpricelist
            // this.api
            //   .postFormAPISM(
            //     {
            //       page: 1,
            //       limit: 1,
            //     },
            //     "/article/carbonpricelist"
            //   )
            //   .then((res) => {
            //     this.nationalMarket = res.data.data.daily_price_list[0];
            //     console.log("getNationalMarket", this.nationalMarket);
            //   })
            //   .catch((error) => {
            //     console.log("error", error);
            //   });
            console.log(this.nationalMarket, "111111111");
            console.log(this.trade, "222222");
        },
        // getTrade() {
        //     // http://192.168.23.15:8000/http://192.168.23.15:8000/article/carbonsalelist
        //     this.api
        //         .postFormAPISM(
        //             {
        //                 page: 1,
        //                 limit: 1,
        //             },
        //             "/article/carbonsalelist"
        //         )
        //         .then((res) => {
        //             this.trade = res.data.data.daily_sale_list[0];
        //             console.log("getTrade", this.trade);
        //         })
        //         .catch((error) => {
        //             console.log("error", error);
        //         });
        // },
        handleViewCandlestick(all) {
            // console.log('all', all);
            // this.$router.push({
            // 	path: '/candlestick',
            // 	query: {
            // 		...all ? { all: 1 } : {},
            // 	}
            // });
            let text = this.$router.resolve({
                path: "/candlestick",
                query: {
                    ...(all ? { all: 1 } : {}),
                },
            });
            // console.log("text", text.href);
            // 打开一个新的页面
            window.open(text.href, "_blank");
        },
    },
};
</script>
<style lang="less" scoped>
.total_wrapper {
    width: 100%;
    //   height: 135px;
    display: flex;
    flex-flow: row nowrap;
    padding: 0 0 40px;

    &>.item {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;

        // padding: 0 0 10px;
        &>div {
            &.name {
                font-family: "微软雅黑", sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                color: #33353c;
                z-index: 10;
            }

            &.value {
                padding: 8px 0 5px;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                height: 42px;

                & .chart {
                    height: 95px;
                    width: 80px;
                    padding-bottom: 55px;
                    margin-left: 40px;
                }

                &>span {
                    font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑",
                        sans-serif;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 22px;
                    // color: #ff2832;
                }

                &>img {
                    position: relative;
                    left: 20px;
                    top: -10px;
                    cursor: pointer;
                }
            }

            &.percent {
                font-family: "微软雅黑", sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                // color: #ff2832;
            }
        }

        &:first-child {
            // padding-left: 10px;
        }

        &:not(:first-child) {
            padding-left: 60px;
        }

        &:not(:last-child) {
            padding-right: 72px;

            &::after {
                position: absolute;
                content: "";
                top: 8px;
                right: 0;
                bottom: -5px;
                // height: 100%;
                width: 1px;
                background-color: rgba(0, 0, 0, 0.2);
            }

            // border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
}
</style>
